import React from 'react'
import Layout from '../components/Layout'
import ArticlesContent from '../components/ArticlesContent'

export default function Index() {
  return (
    <Layout>
      <ArticlesContent />
    </Layout>
  );
}
