import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'

const ArticlesContent = () => {
  const data = useStaticQuery(graphql`
    {
      allArticlesJson {
        edges {
          node {
            title
            image
            description
            slug
            content
          }
        }
      }
    }
  `)

  return <div className = 'articles'>
    {data.allArticlesJson.edges.map (articleItem => <div className = 'article' key = {articleItem.node.title}>
      <div className = 'article__image'>
        <a href = {articleItem.node.slug}>
          <img src = {articleItem.node.image} alt = {articleItem.node.title} />
        </a>
      </div>
      <div className = 'article__details'>
        <div className = 'article__details_title'>
          <a href = {articleItem.node.slug}>
            {articleItem.node.title}
          </a>
        </div>
        <div className = 'article__details_description'>
          {articleItem.node.description}
        </div>
        <div className = 'article__details_article-page'>
          <a href = {articleItem.node.slug}>
            Подробнее
          </a>
        </div>
      </div>
    </div>
    )}
  </div>
}

export default ArticlesContent
